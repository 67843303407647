import moment from "moment";
import { DayOfWeek, Month, NoxioPropertyDataType } from "data/types";

export default function getDayPrice(
  property: NoxioPropertyDataType | null,
  day: moment.Moment
) {
  if (!property) {
    return 0;
  }

  if (!property.ratePlan) {
    return property.standardPrice;
  }

  const ratePlanData = property.ratePlan;

  const dayPrice = ratePlanData.datePrices[day.startOf("day").toISOString()];

  if (dayPrice) {
    return dayPrice;
  }

  const dayOfWeek = day.format("dddd").toLowerCase() as DayOfWeek;

  if (ratePlanData.weeklyBasePrices && ratePlanData.weeklyBasePrices[dayOfWeek])
    return ratePlanData.weeklyBasePrices[dayOfWeek];

  const month = day.format("MMMM").toLowerCase() as Month;
  if (ratePlanData.monthlyBasePrices && ratePlanData.monthlyBasePrices[month])
    return ratePlanData.monthlyBasePrices[month];

  return ratePlanData.standardPrice ?? property.standardPrice ?? 0;
}
