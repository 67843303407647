const amenities = [
  { name: "Shower", icon: "la-shower" },
  { name: "Spa bath", icon: "la-spa" },
  { name: "Pool", icon: "la-swimming-pool" },
  { name: "Flat-screen TV", icon: "la-tv" },
  { name: "Wi-Fi", icon: "la-wifi" },
  { name: "Baby Cribs", icon: "la-baby-carriage" },
  { name: "Bath", icon: "la-bath" },
  { name: "Extra long beds (> 2 metres)", icon: "la-bed" },
  { name: "Coffee Maker", icon: "la-coffee" },
  { name: "Hot tub", icon: "la-hot-tub" },
];

export default amenities;
