import * as React from "react";
import { BookingEngineInfo } from "data/types";

export interface AppContextInterface {
  bookingEngineInfo: BookingEngineInfo | null;
  setBookingEngineInfo: Function;
}

export const AppContext = React.createContext<AppContextInterface | null>(null);

const AppProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [bookingEngineInfo, setBookingEngineInfo] =
    React.useState<BookingEngineInfo | null>(null);

  return (
    <AppContext.Provider
      value={{
        bookingEngineInfo,
        setBookingEngineInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
