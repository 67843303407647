import React, { useEffect } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";

import NoxioSectionGridFeatureProperty from "./NoxioSectionGridFeatureProperty";
import NoxioSectionHero from "components/SectionHero2/NoxioSectionHero2";

function PageHome() {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Noxio || Book Now - Still beta - not production</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <NoxioSectionHero className="lg:mt-2" />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <NoxioSectionGridFeatureProperty />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
