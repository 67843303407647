import React, { useEffect, useState } from "react";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import moment from "moment";
import { FC } from "react";
import NoxioButtonSubmit from "./NoxioButtonSubmit";
import { NoxioSearchFormProps } from "data/types";
import NoxioStayDatesRangeInput from "./NoxioStayDatesRangeInput";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const NoxioStaySearchForm: FC<NoxioSearchFormProps> = ({
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  haveDefaultValue = false,
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [guestValue, setGuestValue] = useState<GuestsInputProps["defaultValue"]>({});

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(null);

  //
  useEffect(() => {
    if (haveDefaultValue && startDate && endDate) {
      const currentStartDate = moment(startDate, "YYYY-MM-DD");
      const currentEndDate = moment(endDate, "YYYY-MM-DD");
      const dateRange: DateRage = {
        startDate: currentStartDate,
        endDate: currentEndDate,
      };
      setDateRangeValue(dateRange);
    }
    setGuestValue({
      guestAdults: guestAdults ? parseInt(guestAdults) : 2,
      guestChildren: guestChildren ? parseInt(guestChildren) : 0,
      guestInfants: guestInfants ? parseInt(guestInfants) : 0,
    });
  }, []);
  //
  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
        <NoxioStayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onFocusChange={(focus) => setDateFocused(focus)}
          onChange={(data) => setDateRangeValue(data)}
        />
        <GuestsInput defaultValue={guestValue} onChange={(data) => setGuestValue(data)} />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0">
          <NoxioButtonSubmit
            startDate={dateRangeValue.startDate}
            endDate={dateRangeValue.endDate}
            guestAdults={guestValue.guestAdults}
            guestChildren={guestValue.guestChildren}
            guestInfants={guestValue.guestInfants}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default NoxioStaySearchForm;
