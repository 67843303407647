export function setUrlParameter(parameter: string, value: string) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(parameter, value);
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
}

export function setUrlParameters(parameter: string, values: string[]) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(parameter);
  values.forEach((x) => {
    searchParams.append(parameter, x);
  });

  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
}
