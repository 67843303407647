import React, { FC, ReactNode, useEffect, useState } from "react";
import { NoxioPropertyDataType } from "data/types";
import NoxioHeader from "./NoxioHeader";
import NoxioPropertyCardH from "components/PropertyCardH/NoxioPropertyCardH";
import NoxioService from "services/NoxioService";
import { useParams } from "react-router-dom";
import { RouteParams } from "routers/types";
export interface SectionGridFeaturePropertyProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
}

const NoxioSectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = "",
  heading = "Choose property to stay",
  subHeading = "Popular places to stay recommended for you",
}) => {
  const noxioService = new NoxioService();
  const [properties, setProperties] = useState<NoxioPropertyDataType[]>([]);

  const params = useParams<RouteParams>();

  useEffect(() => {
    const getProperties = async () => {
      const newProperties = await noxioService.getAllProperty(params.account);
      setProperties(newProperties);
    };

    getProperties();
  }, []);

  const renderCard = (stay: NoxioPropertyDataType, index: number) => {
    return <NoxioPropertyCardH key={index} className="h-full" data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <NoxioHeader subHeading={subHeading} heading={heading} />
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
      >
        {properties.map(renderCard)}
      </div>
    </div>
  );
};

export default NoxioSectionGridFeatureProperty;
