import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageHome2 from "containers/PageHome/PageHome1";
import Header from "components/Header/Header";
import NoxioStayDetailPage from "containers/ListingDetailPage/NoxioStayDetailPage";
import NoxioListingStayPage from "containers/ListingStayPage/NoxioListingStayPage";
import NoxioReservePage from "containers/ReservePage/NoxioReservePage";
import NoxioPayPage from "containers/PayPage/NoxioPayPage";
import BookingInfoPage from "containers/BookingInfoPage/BookingInfoPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: Page404 },
  { path: "/:account", exact: true, component: PageHome },
  { path: "/:account/#", exact: true, component: PageHome },
  { path: "/:account/home-2", component: PageHome2 },
  //
  { path: "/:account/search", component: NoxioListingStayPage },
  { path: "/:account/listing-stay", component: ListingStayPage },
  { path: "/:account/listing-stay-map", component: ListingStayMapPage },
  { path: "/:account/stay-detail/:urlName", component: NoxioStayDetailPage },
  //
  {
    path: "/:account/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/:account/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/:account/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/:account/checkout", component: CheckOutPage },
  { path: "/:account/pay-done", component: PayPage },
  //
  { path: "/:account/reserve", component: NoxioReservePage },
  { path: "/:account/reserve-done", component: NoxioPayPage },
  //
  { path: "/:account/yourbooking/:id", component: BookingInfoPage },
  //
  { path: "/:account/account", component: AccountPage },
  { path: "/:account/account-password", component: AccountPass },
  { path: "/:account/account-savelists", component: AccountSavelists },
  { path: "/:account/account-billing", component: AccountBilling },
  //
  { path: "/:account/contact", component: PageContact },
  { path: "/:account/about", component: PageAbout },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return <Route key={path} component={component} exact={!!exact} path={path} />;
        })}
        <Route component={Page404} />
      </Switch>
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default Routes;
