import axios from "./axios";

export default class Service {
  getResource = async (url: string) => {
    const res = await axios.get(url);

    if (res.status !== 200) {
      throw res;
    }

    return await res.data;
  };

  getResourcePost = async (url: string, body = {}) => {
    const res = await axios.post(url, body);

    if (res.status !== 200) {
      throw res;
    }

    return await res.data;
  };
}
