import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import NoxioSectionGridFilterCard from "./NoxioSectionGridFilterCard";
import NoxioService from "services/NoxioService";
import NoxioStaySearchForm from "components/HeroSearchForm/NoxioStaySearchForm";
import { useParams } from "react-router-dom";
import { RouteParams } from "routers/types";
import { NoxioPropertyDataType } from "data/types";
import moment from "moment";
import NoxioBgGlassmorphism from "components/BgGlassmorphism/NoxioBgGlassmorphism";
export interface NoxioListingStayPageProps {
  className?: string;
}

const NoxioListingStayPage: FC<NoxioListingStayPageProps> = ({ className = "" }) => {
  const searchParams = new URLSearchParams(window.location.search);

  const [searchResults, setSearchResults] = useState<NoxioPropertyDataType[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(searchParams.get("startDate") ?? "");
  const [endDate, setEndDate] = useState<string>(searchParams.get("endDate") ?? "");
  const [guestAdults, setGuestAdults] = useState<string>(
    searchParams.get("guestAdults") ?? "0"
  );
  const [guestChildren, setGuestChildren] = useState<string>(
    searchParams.get("guestChildren") ?? "0"
  );
  const [guestInfants, setGuestInfants] = useState<string>(
    searchParams.get("guestInfants") ?? "0"
  );

  //Tab filters
  const [rangePrices, setRangePrices] = useState([
    parseInt(searchParams.get("minPrice") ?? "0") ?? 0,
    parseInt(searchParams.get("maxPrice") ?? "10000") ?? 10000,
  ]);
  const [beds, setBeds] = useState(parseInt(searchParams.get("beds") ?? "0") ?? 0);
  const [bedrooms, setBedrooms] = useState(
    parseInt(searchParams.get("bedrooms") ?? "0") ?? 0
  );
  const [bathrooms, setBathrooms] = useState(
    parseInt(searchParams.get("bathrooms") ?? "0") ?? 0
  );
  const [propertyTypes, setPropertyTypes] = useState(
    searchParams.getAll("placeType") ?? []
  );

  const params = useParams<RouteParams>();

  const filters = {
    rangePrices: rangePrices,
    setRangePrices: setRangePrices,
    beds: beds,
    setBeds: setBeds,
    bedrooms: bedrooms,
    setBedrooms: setBedrooms,
    bathrooms: bathrooms,
    setBathrooms: setBathrooms,
    propertyTypes: propertyTypes,
    setPropertyTypes: setPropertyTypes,
  };

  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeout.current as NodeJS.Timeout);

    const getSearchResults = async () => {
      setSearchLoading(true);
      let result = await new NoxioService().getSearchResults(
        params.account,
        startDate,
        endDate,
        guestAdults,
        guestChildren,
        guestInfants,
        rangePrices[0],
        rangePrices[1],
        beds,
        bedrooms,
        bathrooms,
        propertyTypes
      );

      setSearchResults(result);
      setSearchLoading(false);
    };

    timeout.current = setTimeout(() => {
      getSearchResults();
    }, 1000);
  }, [
    startDate,
    endDate,
    guestAdults,
    guestChildren,
    guestInfants,
    rangePrices,
    beds,
    bedrooms,
    bathrooms,
    propertyTypes,
  ]);

  useEffect(() => {
    setStartDate(searchParams.get("startDate") ?? "");
    setEndDate(searchParams.get("endDate") ?? "");
    setGuestAdults(searchParams.get("guestAdults") ?? "");
    setGuestChildren(searchParams.get("guestChildren") ?? "");
    setGuestInfants(searchParams.get("guestInfants") ?? "");
  }, [searchParams]);

  return (
    <div
      className={`nc-ListingExperiencesMapPage relative ${className}`}
      data-nc-id="ListingExperiencesMapPage"
    >
      <Helmet>
        <title>Noxio || Search</title>
      </Helmet>

      <div className="relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div
          className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 lg:mt-2 m-auto`}
          data-nc-id="HeroSearchForm"
        >
          <NoxioStaySearchForm
            haveDefaultValue={true}
            startDate={startDate}
            endDate={endDate}
            guestAdults={guestAdults}
            guestChildren={guestChildren}
            guestInfants={guestInfants}
            className="lg:mt-2 m-auto"
          />
        </div>

        {/* SECTION */}
        <NoxioBgGlassmorphism />
        <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
          <NoxioSectionGridFilterCard
            data={searchResults}
            isLoading={searchLoading}
            startDate={moment.utc(startDate)}
            endDate={moment.utc(endDate)}
            guestAdults={guestAdults}
            guestChildren={guestChildren}
            guestInfants={guestInfants}
            className="pb-24 lg:pb-32"
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
};

export default NoxioListingStayPage;
