import React, { FC } from "react";

export interface NoxioMarkerProps {
  lat: number;
  lng: number;
  info: string;
  onClick: () => void;
}

const NoxioMarker: FC<NoxioMarkerProps> = ({ info, onClick }) => {
  return (
    <div
      className="flex items-center justify-center w-11 h-11 bg-primary-500 hover:bg-primary-400 text-xl font-semibold text-white p-2 rounded-full cursor-pointer"
      onClick={onClick}
    >
      {info}
    </div>
  );
};

export default NoxioMarker;
