import moment from "moment";
import React from "react";
import { ReactNode } from "react";

export interface NoxioHeading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  stays: number;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  guests: number;
}

const NoxioHeading2: React.FC<NoxioHeading2Props> = ({
  className = "",
  heading = "Available properties",
  subHeading,
  stays,
  startDate,
  endDate,
  guests,
}) => {
  return (
    <div className={`flex flex-col mb-12 lg:mb-16 relative ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {stays} properties
          <span className="mx-2">·</span>
          {startDate && startDate.isValid() && endDate && endDate.isValid() && (
            <>
              {` ${startDate?.format("D MMM")} - ${endDate?.format("D MMM")}`}
              <span className="mx-2">·</span>
            </>
          )}
          {guests} Guests
        </span>
      )}
    </div>
  );
};

export default NoxioHeading2;
