import { NoxioSearchFormProps } from "data/types";
import React, { FC } from "react";
import "react-dates/initialize";
import NoxioStaySearchForm from "./NoxioStaySearchForm";

const NoxioHeroSearchForm: FC<NoxioSearchFormProps> = ({
  className,
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  haveDefaultValue = true,
}) => {
  const renderForm = () => {
    return (
      <NoxioStaySearchForm
        haveDefaultValue={haveDefaultValue}
        guestAdults={guestAdults}
        guestChildren={guestChildren}
        guestInfants={guestInfants}
      />
    );
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderForm()}
    </div>
  );
};

export default NoxioHeroSearchForm;
