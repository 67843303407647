import React, { FC } from "react";
import Heading from "shared/Heading/Heading";
import { ReactNode } from "react";

export interface HeaderFilterProps {
  heading: ReactNode;
  subHeading?: ReactNode;
}

const NoxioHeader: FC<HeaderFilterProps> = ({
  subHeading = "",
  heading = "🎈 Latest Articles",
}) => {
  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
    </div>
  );
};

export default NoxioHeader;
