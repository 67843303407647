import { NoxioPropertyDataType } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface NoxioButtonReserveProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  guestAdults: number | undefined;
  guestChildren: number | undefined;
  guestInfants: number | undefined;
  property: NoxioPropertyDataType | null;
  totalPrice: number | undefined;
  nights: number | undefined;
  blockedDates: string[];
  checkinInFuture?: number | null;
}

const NoxioButtonReserve: FC<NoxioButtonReserveProps> = ({
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  property,
  totalPrice,
  nights,
  blockedDates,
  checkinInFuture,
}) => {
  const accountName = window.location.pathname.split("/")[1];
  return startDate && endDate ? (
    <Link
      to={{
        pathname: `/${accountName}/reserve`,
        state: {
          startDate: startDate?.startOf("day").toISOString(),
          endDate: endDate?.startOf("day").toISOString(),
          guestAdults: guestAdults,
          guestChildren: guestChildren,
          guestInfants: guestInfants,
          property: property,
          totalPrice,
          nights,
          blockedDates,
          checkinInFuture,
        },
      }}
      type="button"
      className="h-10 md:h-12 w-full rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50"
    >
      Reserve
    </Link>
  ) : (
    <button
      disabled
      className="h-10 md:h-12 w-full rounded-full bg-neutral-300 hover:bg-neutral-400 flex items-center justify-center text-neutral-50"
    >
      Reserve
    </button>
  );
};

export default NoxioButtonReserve;
