import { UpdateBookData } from "containers/BookingInfoPage/BookingInfoPage";
import { BookData } from "containers/ReservePage/NoxioReservePage";
import { NoxioPropertyDataType } from "data/types";
import Service from "./service";

export default class NoxioService extends Service {
  getAllProperty = async (account: string): Promise<NoxioPropertyDataType[]> => {
    const properties = await this.getResourcePost("/allProperty", { account });
    return properties;
  };

  getPropertyByUrlName = async (urlName: string): Promise<NoxioPropertyDataType> => {
    const property = await this.getResource(`/property/${urlName}`);
    return property;
  };

  getSearchResults = async (
    account: string,
    startDate: string,
    endDate: string,
    guestAdults: string,
    guestChildren: string,
    guestInfants: string,
    minPrice: number,
    maxPrice: number,
    beds: number,
    bedrooms: number,
    bathrooms: number,
    propertyTypes: string[]
  ) => {
    const properties = await this.getResourcePost("/search", {
      account,
      startDate,
      endDate,
      guestAdults,
      guestChildren,
      guestInfants,
      minPrice,
      maxPrice,
      beds,
      bedrooms,
      bathrooms,
      propertyTypes,
    });
    return properties;
  };

  getBlockedDates = async (urlName: string, account: string) => {
    const info = await this.getResourcePost(`/getBlockedDates/${urlName}`, { account });
    return info;
  };

  getBookingEngineInfo = async (account: string) => {
    const info = await this.getResourcePost(`/getBookingEngineInfo`, {
      account,
    });
    return info;
  };

  getPrices = async (account: string) => {
    const prices = await this.getResourcePost("/prices", { account });
    return prices;
  };

  createBooking = async (bookData: BookData) => {
    const result = await this.getResourcePost("/createBooking", { bookData });
    return result;
  };

  getBookingInfo = async (account: string, bookingId: string) => {
    const result = await this.getResourcePost(`/getBookingInfo/${bookingId}`, {
      account,
    });
    return result;
  };

  updateBookingInfo = async (bookingId: string, bookData: UpdateBookData) => {
    const result = await this.getResourcePost(`/updateBookingInfo/${bookingId}`, {
      bookData,
    });
    return result;
  };

  checkCouponCode = async (
    account: string,
    couponCode: string,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => {
    const result = await this.getResourcePost(`/checkCouponCode`, {
      account,
      couponCode,
      startDate,
      endDate,
    });
    return result;
  };
}
