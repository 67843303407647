import React, { useEffect, useState } from "react";
import { AnchorDirectionShape, DateRangePicker, FocusedInputShape } from "react-dates";
import { DateRage } from "./StaySearchForm";
import { FC } from "react";
import useWindowSize from "hooks/useWindowResize";
import NoxioService from "services/NoxioService";
import { useParams } from "react-router-dom";
import { RouteParams } from "routers/types";
import { NoxioMonthlyBasePrices, NoxioPrice, NoxioWeeklyBasePrices } from "data/types";
import moment from "moment";
import isDayBlocked from "utils/isDayBlocked";
import { AppContext, AppContextInterface } from "context/appContext";
export interface NoxioStayDatesRangeInputProps {
  defaultValue: DateRage;
  defaultFocus?: FocusedInputShape | null;
  onChange?: (data: DateRage) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  blockedDates?: any[];
  propertyStandardPrice?: number;
  propertyPrices?: NoxioPrice;
  isOnPropertyPage?: boolean;
}

const NoxioStayDatesRangeInput: FC<NoxioStayDatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  numberOfMonths,
  anchorDirection,
  blockedDates,
  propertyStandardPrice,
  propertyPrices,
  isOnPropertyPage = false,
}) => {
  const { bookingEngineInfo, setBookingEngineInfo } = React.useContext(
    AppContext
  ) as AppContextInterface;

  const disallowBookingInfo = bookingEngineInfo?.disallowBookingInfo;

  const params = useParams<RouteParams>();
  const noxioService = new NoxioService();

  const [prices, setPrices] = useState<NoxioPrice>();

  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultValue);

  const windowSize = useWindowSize();

  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    if (onChange) {
      onChange(stateDate);
    }
  }, [stateDate]);

  useEffect(() => {
    if (propertyStandardPrice && propertyPrices) {
      setPrices(propertyPrices);
    } else {
      const getPrices = async () => {
        const newPrices = await noxioService.getPrices(params.account);
        setPrices(newPrices);
      };

      getPrices();
    }

    if (!disallowBookingInfo) {
      const getBookingEngineInfo = async () => {
        const newBookingEngineInfo = await noxioService.getBookingEngineInfo(
          params.account
        );
        setBookingEngineInfo(newBookingEngineInfo);
      };

      getBookingEngineInfo();
    }
  }, []);

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer text-center ${
          focused ? "nc-hero-field-focused" : " "
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {stateDate.startDate ? stateDate.startDate.format("DD MMM") : "Check in"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {stateDate.startDate ? "Check in" : `Add date`}
          </span>
        </div>
      </div>
    );
  };

  const renderInputCheckOutDate = () => {
    const focused = focusedInput === "endDate";
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer text-center ${
          focused ? "nc-hero-field-focused" : " "
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {stateDate.endDate ? stateDate.endDate.format("DD MMM") : "Check out"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {stateDate.endDate ? "Check out" : `Add date`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`StayDatesRangeInput relative flex-shrink-0 flex z-10 [ lg:nc-flex-2 ] ${
        !!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
      }`}
    >
      <div className="absolute inset-0 flex">
        <DateRangePicker
          startDate={stateDate.startDate}
          endDate={stateDate.endDate}
          focusedInput={focusedInput}
          onDatesChange={(date) => {
            if (
              blockedDates &&
              blockedDates.some((blockedDate) => {
                return (
                  date.startDate &&
                  date.endDate &&
                  moment(blockedDate).isBetween(date.startDate, date.endDate)
                );
              })
            ) {
              return;
            } else if (
              date.endDate &&
              date.startDate &&
              disallowBookingInfo?.maxStay &&
              disallowBookingInfo?.maxStayValue &&
              date.endDate.diff(date.startDate, "days") >
                disallowBookingInfo?.maxStayValue
            ) {
              return;
            } else {
              setStateDate(date);
            }
          }}
          onFocusChange={handleDateFocusChange}
          numberOfMonths={numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)}
          startDateId={"nc-hero-stay-startDateId"}
          endDateId={"nc-hero-stay-endDateId"}
          daySize={windowSize.width > 500 ? 56 : undefined}
          orientation={"horizontal"}
          showClearDates
          noBorder
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel
          anchorDirection={anchorDirection}
          minimumNights={
            disallowBookingInfo?.minStay && disallowBookingInfo?.minStayValue
              ? disallowBookingInfo.minStayValue
              : 0
          }
          isDayBlocked={(day) =>
            isDayBlocked(
              day,
              blockedDates,
              disallowBookingInfo?.checkinInFuture &&
                disallowBookingInfo.checkinInFutureValue
                ? disallowBookingInfo.checkinInFutureValue
                : null,
              disallowBookingInfo?.dateBeforeCheckin &&
                disallowBookingInfo.dateBeforeCheckinValue
                ? disallowBookingInfo.dateBeforeCheckinValue
                : null
            )
          }
          renderDayContents={(day) => {
            if (prices) {
              const cloneDay = day.clone();
              const standardPrice = prices.standardPrice;

              const currentPrice = !isOnPropertyPage
                ? prices.datePrices[cloneDay.startOf("day").toISOString()] &&
                  prices.datePrices[cloneDay.startOf("day").toISOString()] < standardPrice
                  ? prices.datePrices[cloneDay.startOf("day").toISOString()]
                  : standardPrice
                : prices.datePrices[cloneDay.startOf("day").toISOString()] ??
                  standardPrice;

              const isBigger = currentPrice > standardPrice;
              const isSmaller = currentPrice < standardPrice;

              const className = `text-xs ${isBigger ? "text-red-400" : ""} ${
                isSmaller ? "text-green-400" : ""
              }`;

              return (
                <div>
                  <p>{cloneDay.date()}</p>
                  <p className={className}>
                    &euro;
                    {currentPrice}
                  </p>
                </div>
              );
            } else {
              return (
                <div>
                  <p>{day.date()}</p>
                </div>
              );
            }
          }}
          renderCalendarInfo={() => (
            <>
              {disallowBookingInfo?.minStay && disallowBookingInfo?.minStayValue ? (
                <div className="px-5 py-3">{`Minimum night - ${disallowBookingInfo.minStayValue}`}</div>
              ) : null}
              {disallowBookingInfo?.maxStay && disallowBookingInfo?.maxStayValue ? (
                <div className="px-5 py-3">{`Maximum night - ${disallowBookingInfo.maxStayValue}`}</div>
              ) : null}
            </>
          )}
        />
      </div>

      <div
        className={`flex flex-col lg:flex-row lg:items-center w-full flex-shrink-0 relative  ${wrapClassName}`}
      >
        {renderInputCheckInDate()}

        {renderInputCheckOutDate()}
      </div>
    </div>
  );
};

export default NoxioStayDatesRangeInput;
