import React from "react";
import MyRouter from "routers/index";
import AppProvider from "context/appContext";


function App() {
  return (
    <AppProvider>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
    </AppProvider>
  );
}

export default App;
