import moment from "moment";

export interface SelectTimes {
  display: string;
  value: string;
}

export default function getTimeArray(): SelectTimes[] {
  let timeArray: SelectTimes[] = [];
  const defaultTime = moment("1970-01-01T00:00:00.000+00:00").tz("UTC");

  for (let index = 0; index < 48; index++) {
    let displayValue = defaultTime.format("HH:mm");

    timeArray.push({ display: displayValue, value: defaultTime.toISOString() });

    defaultTime.add(30, "minutes");
  }

  return timeArray;
}
