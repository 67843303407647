import React, { FC, useEffect, useState } from "react";
import GoogleMapReact, { ChangeEventValue } from "google-map-react";
import { NoxioAddress, NoxioPropertyDataType } from "data/types";
// import TabFilters from "./TabFilters";
import NoxioHeading2 from "components/Heading/NoxioHeading2";
import NoxioPropertyCardH from "components/PropertyCardH/NoxioPropertyCardH";
import moment from "moment";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NoxioAnyReactComponent from "components/AnyReactComponent/NoxioAnyReactComponent";
import NoxioTabFilters from "./NoxioTabFilters";
import useSupercluster from "use-supercluster";
import NoxioMarker from "components/AnyReactComponent/NoxioMarker";
import getGoogleMapApiKey from "utils/getGoogleMapApiKey";
export interface NoxioSectionGridFilterCardProps {
  className?: string;
  data?: NoxioPropertyDataType[];
  isLoading: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  guestAdults: string;
  guestChildren: string;
  guestInfants: string;
  filters: any;
}

const NoxioSectionGridFilterCard: FC<NoxioSectionGridFilterCardProps> = ({
  className = "",
  data = [],
  isLoading = false,
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  filters,
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [currentSelectedID, setCurrentSelectedID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [showBigScreenMap, setShowBigScreenMap] = useState(true);
  const [currentCenter, setCurrentCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [currentZoom, setCurrentZoom] = useState(12);
  const [bounds, setBounds] = useState<number[]>([]);

  useEffect(() => {
    if (data.length > 0) {
      const propertiesWithLocation = data.filter(
        (x) => x.address && x.address.latitude && x.address.longitude
      );

      let maxLat = parseFloat(
        propertiesWithLocation.reduce(function (prev, curr) {
          return prev.address.latitude > curr.address.latitude ? prev : curr;
        }).address.latitude
      );

      let minLat = parseFloat(
        propertiesWithLocation.reduce(function (prev, curr) {
          return prev.address.latitude < curr.address.latitude ? prev : curr;
        }).address.latitude
      );

      let maxLng = parseFloat(
        propertiesWithLocation.reduce(function (prev, curr) {
          return prev.address.longitude > curr.address.longitude ? prev : curr;
        }).address.longitude
      );

      let minLng = parseFloat(
        propertiesWithLocation.reduce(function (prev, curr) {
          return prev.address.longitude < curr.address.longitude ? prev : curr;
        }).address.longitude
      );

      let multipleLat = (minLat + maxLat) / 2;
      let multipleLng = (minLng + maxLng) / 2;

      setCurrentCenter({ lat: multipleLat, lng: multipleLng });

      const latDifference = maxLat - minLat;
      const lngDifference = maxLng - minLng;

      if (latDifference > 0.2 || lngDifference > 0.2) {
        setCurrentZoom(11);
      }
    }
  }, [data]);

  const onShowMapHandler = (address: NoxioAddress, id: string) => {
    const lat = parseFloat(address.latitude);
    const lng = parseFloat(address.longitude);

    if (lat && lng) {
      if (
        Math.abs(currentCenter.lat - lat) > 0.001 ||
        Math.abs(currentCenter.lng - lng) > 0.001
      ) {
        setCurrentZoom(19);
      }
      setCurrentCenter({ lat, lng });
      setCurrentSelectedID(id);
    }
  };

  const onMapChangeHandler = (mapData: ChangeEventValue) => {
    if (mapData.center.lat !== 0 && mapData.center.lng !== 0) {
      setCurrentCenter({ lat: mapData.center.lat, lng: mapData.center.lng });
      setCurrentZoom(mapData.zoom);

      setBounds([
        mapData.bounds.nw.lng,
        mapData.bounds.se.lat,
        mapData.bounds.se.lng,
        mapData.bounds.nw.lat,
      ]);
    }
  };

  /*CREATE POINTS*/
  const points = data
    .filter((property) => property.address.latitude && property.address.longitude)
    .map((property) => ({
      type: "Feature",
      properties: {
        cluster: false,
        property: property,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(property.address.longitude),
          parseFloat(property.address.latitude),
        ],
      },
    }));

  points.forEach((point) => {
    let [lng, lat] = point.geometry.coordinates;
    points.forEach((x) => {
      let lngPlus = 0.0001;
      let latPlus = 0.0001;

      if (
        x.properties.property._id !== point.properties.property._id &&
        x.geometry.coordinates[0] === lng &&
        x.geometry.coordinates[1] === lat
      ) {
        x.geometry.coordinates = [
          x.geometry.coordinates[0] + lngPlus,
          x.geometry.coordinates[1] + latPlus,
        ];

        lngPlus += 0.0001;
        latPlus += 0.0001;
      }
    });
  });

  /* GET CLUSTER*/

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: currentZoom,
    options: {
      radius: 75,
      maxZoom: 20,
    },
  });

  return (
    <div>
      <div className="relative flex min-h-screen">
        <div
          className={`min-h-screen w-full ${
            showBigScreenMap ? "xl:w-[780px] 2xl:w-[880px]" : ""
          }  flex-shrink-0 xl:px-8 `}
        >
          <NoxioHeading2
            stays={data.length}
            startDate={startDate}
            endDate={endDate}
            guests={+guestAdults + +guestChildren}
          />
          <div className="mb-8 lg:mb-11">
            <NoxioTabFilters filters={filters} />
          </div>

          {isLoading ? (
            <div>
              <div className="relative flex justify-center">
                <span className="animate-spin ml-5">
                  <i className="las la-spinner text-8xl text-primary-500"></i>
                </span>
              </div>
            </div>
          ) : !isLoading && data.length === 0 ? (
            <div className={`flex flex-col mb-12 lg:mb-16 relative ${className}`}>
              <h2 className="text-4xl font-semibold">We are sorry</h2>
              There are no properties with your filtrers
            </div>
          ) : (
            <>
              <div
                className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                onClick={() => setShowFullMapFixed(true)}
              >
                <i className="text-lg las la-map"></i>
                <span>Show map</span>
              </div>
              <div className={`grid grid-cols-${showBigScreenMap ? "1" : "2"} gap-8`}>
                {data.map((stay) => (
                  <div
                    key={stay._id}
                    onMouseEnter={() => {
                      setCurrentSelectedID((_) => -1);
                      setCurrentHoverID((_) => stay._id);
                    }}
                    onMouseLeave={() => setCurrentHoverID((_) => -1)}
                  >
                    <NoxioPropertyCardH
                      data={stay}
                      isSelectedDates={true}
                      startDate={startDate}
                      endDate={endDate}
                      guestAdults={guestAdults}
                      guestChildren={guestChildren}
                      guestInfants={guestInfants}
                      onShowOnMap={() => onShowMapHandler(stay.address, stay._id)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {!isLoading && data.length === 0 ? null : (
          <>
            <div
              className="hidden xl:flex items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
              onClick={() => setShowBigScreenMap(!showBigScreenMap)}
            >
              <i className="text-lg las la-map"></i>
              <span>{`${showBigScreenMap ? "Hide" : "Show"} map`}</span>
            </div>
            {/* MAPPPPP RENDERING*/}
            <div
              className={`${
                showBigScreenMap || showFullMapFixed
                  ? "xl:flex-grow xl:static xl:block"
                  : "hidden"
              } ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"}`}
            >
              {showFullMapFixed && (
                <ButtonClose
                  onClick={() => setShowFullMapFixed(false)}
                  className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                />
              )}
              <div
                onClick={() => setCurrentSelectedID((_) => -1)}
                className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden"
              >
                {data[0] && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: getGoogleMapApiKey(),
                    }}
                    options={{ clickableIcons: false }}
                    yesIWantToUseGoogleMapApiInternals
                    center={currentCenter}
                    zoom={currentZoom}
                    onChange={onMapChangeHandler}
                  >
                    {clusters.map((cluster) => {
                      const [longitude, latitude] = cluster.geometry.coordinates;
                      const {
                        cluster: isCluster,
                        property,
                        point_count: pointCount,
                      } = cluster.properties;

                      if (isCluster) {
                        return (
                          <NoxioMarker
                            key={cluster.id}
                            lat={latitude}
                            lng={longitude}
                            info={pointCount}
                            onClick={() => {
                              const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(cluster.id),
                                20
                              );
                              setCurrentZoom(expansionZoom);
                              setCurrentCenter({ lat: latitude, lng: longitude });
                            }}
                          ></NoxioMarker>
                        );
                      }

                      return (
                        latitude &&
                        longitude && (
                          <NoxioAnyReactComponent
                            isSelected={currentHoverID === property._id}
                            showCard={currentSelectedID === property._id}
                            key={property._id}
                            lat={latitude}
                            lng={longitude}
                            data={property}
                            isSelectedDates={true}
                            startDate={startDate}
                            endDate={endDate}
                            guestAdults={guestAdults}
                            guestChildren={guestChildren}
                            guestInfants={guestInfants}
                          />
                        )
                      );
                    })}
                  </GoogleMapReact>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NoxioSectionGridFilterCard;
