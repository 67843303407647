import React, { FC, useRef, useState } from "react";
import moment from "moment";
import { RouteParams } from "routers/types";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import { NoxioPropertyDataType } from "data/types";
import { RouteComponentProps, useLocation, useParams } from "react-router-dom";
import NoxioNcImage from "shared/NcImage/NoxioNcImage";
import NoxioService from "services/NoxioService";
import NoxioStayDatesRangeInput from "components/HeroSearchForm/NoxioStayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import countries from "../../data/countries";
import { GuestsInputProps } from "components/HeroSearchForm/GuestsInput";
import NoxioGuestsInput from "components/HeroSearchForm/NoxioGuestsInput";

import propertyPrice from "utils/propertyPrice";

export interface NoxioReservePageProps extends RouteComponentProps {
  className?: string;
}

interface LocationState {
  property: NoxioPropertyDataType;
  startDate: moment.Moment;
  endDate: moment.Moment;
  guestAdults: number;
  guestChildren: number;
  guestInfants: number;
  totalPrice: number;
  nights: number;
  blockedDates: any[];
}

interface Coupon {
  calculatedBy: string;
  couponCode: string;
  couponName: string;
  discount: number;
}

export interface BookData {
  account?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  city?: string;
  country?: string;
  propertyId?: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  guestAdults?: number;
  guestChildren?: number;
  guestInfants?: number;
  message?: string;
  couponCode?: string;
}

const NoxioReservePage: FC<NoxioReservePageProps> = ({ className = "", history }) => {
  const dataState = useLocation<LocationState>().state;
  const params = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>();
  const [couponChecking, setCouponChecking] = useState(false);

  const {
    property,
    totalPrice,
    nights,
    startDate,
    endDate,
    guestAdults,
    guestChildren,
    guestInfants,
    blockedDates,
  } = dataState;

  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment.utc(startDate),
    endDate: moment.utc(endDate),
  });

  const [selectedGuestValue, setSelectedGuestValue] = useState<
    GuestsInputProps["defaultValue"]
  >({ guestAdults, guestChildren, guestInfants });

  const [currentNights, setCurrentNights] = useState(nights);
  const [currentTotalPrice, setCurrentTotalPrice] = useState(totalPrice);

  const [bookData, setBookData] = useState<BookData>({
    propertyId: property._id,
    account: params.account,
    guestAdults: guestAdults,
    guestChildren: guestChildren,
    guestInfants: guestInfants,
    startDate: startDate,
    endDate: endDate,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    country: "",
    couponCode: "",
  });

  const onInputChange = (name: string, value: string) => {
    setBookData((prev) => {
      let newData: any = { ...prev };
      newData[name] = value;

      return newData;
    });
  };

  const isBookDataFilled = () => {
    return (
      bookData.email &&
      bookData.firstName &&
      bookData.lastName &&
      bookData.phone &&
      bookData.city &&
      bookData.country
    );
  };

  const isEmailValid = (email: string) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const confirmHandler = async () => {
    if (!isBookDataFilled()) return;
    setLoading(true);

    bookData.startDate = selectedDate.startDate;
    bookData.endDate = selectedDate.endDate;

    bookData.guestAdults = selectedGuestValue.guestAdults;
    bookData.guestChildren = selectedGuestValue.guestChildren;
    bookData.guestInfants = selectedGuestValue.guestInfants;

    const { number, price, id } = await new NoxioService().createBooking(bookData);
    history.push({
      pathname: `/${params.account}/reserve-done`,
      state: {
        bookingNumber: number,
        property: property,
        startDate: selectedDate.startDate?.toISOString(),
        endDate: selectedDate.endDate?.toISOString(),
        guests: guestAdults + guestChildren + guestInfants,
        price: price,
        bookingId: id,
      },
    });
  };

  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const checkCouponCode = async (
    couponCode: string,
    startDate = bookData.startDate,
    endDate = bookData.endDate
  ) => {
    if (couponCode) {
      let coupon = await new NoxioService().checkCouponCode(
        params.account,
        couponCode,
        startDate,
        endDate
      );
      setCoupon(coupon);
    }
    setCouponChecking(false);
  };

  const checkCouponCodeHandler = async (couponCode: string) => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    onInputChange("couponCode", couponCode);

    setCouponChecking(true);

    timeout.current = setTimeout(() => {
      checkCouponCode(couponCode);
    }, 1000);
  };

  const changeDateHandler = (date: DateRage) => {
    setSelectedDate(date);

    const currentStartDate = date.startDate?.clone();

    const currentEndDate = date.endDate?.clone();
    setCurrentNights(currentEndDate ? currentEndDate?.diff(currentStartDate, "days") : 0);

    if (currentStartDate && currentEndDate && property) {
      const newTotalPrice = propertyPrice(currentStartDate, currentEndDate, property);
      setCurrentTotalPrice(newTotalPrice);
    }

    if (bookData.couponCode) {
      setCouponChecking(true);
      checkCouponCode(bookData.couponCode, date.startDate, date.endDate);
    }
  };

  const discountTotalPrice = coupon
    ? coupon.calculatedBy === "Fixed"
      ? Math.max(currentTotalPrice - coupon.discount, 0)
      : Math.max((currentTotalPrice / 100) * (100 - coupon.discount), 0)
    : currentTotalPrice;

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NoxioNcImage src={property.media[0] ? property.media[0]._id : ""} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {property.publicName}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {property.beds} beds · {property.bathrooms} baths
            </span>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              &euro;{(currentTotalPrice / currentNights).toFixed(2)} x {currentNights} day
            </span>
            <span>&euro;{currentTotalPrice}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {coupon && (
            <h6 className="text-green-400 text-lg font-semibold">
              {`-${coupon.discount} ${
                coupon.calculatedBy === "Fixed" ? "euro" : "%"
              } discount`}
            </h6>
          )}
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>&euro;{discountTotalPrice}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Confirm Booking</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your reservation</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-auto p-5 flex justify-between space-x-5">
              <NoxioStayDatesRangeInput
                wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
                onChange={(date) => {
                  changeDateHandler(date);
                }}
                numberOfMonths={1}
                fieldClassName="p-5"
                defaultValue={selectedDate}
                blockedDates={blockedDates}
                isOnPropertyPage={true}
                propertyPrices={property.ratePlan}
                propertyStandardPrice={property.standardPrice}
              />
            </div>
            <div className="flex-auto p-5 flex justify-between space-x-5">
              <NoxioGuestsInput
                defaultValue={selectedGuestValue}
                onChange={(data) => {
                  setSelectedGuestValue(data);
                }}
                maximumGuests={property?.sleeps ?? 0}
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Guest Information</h3>
          <div className="mt-6">
            <div className="w-14 border-b border-neutral-200 my-5"></div>
            <div className="space-y-4">
              <div className="flex space-x-5  ">
                <div className="flex-1 space-y-1">
                  <Label>First Name</Label>
                  <Input
                    value={bookData?.firstName}
                    onChange={(e) => onInputChange("firstName", e.target.value)}
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Last name</Label>
                  <Input
                    value={bookData?.lastName}
                    onChange={(e) => onInputChange("lastName", e.target.value)}
                  />
                </div>
              </div>
              <div className="flex space-x-5  ">
                <div className="flex-1 space-y-1">
                  <Label>Email</Label>
                  <Input
                    value={bookData?.email}
                    type="email"
                    onChange={(e) => onInputChange("email", e.target.value)}
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Phone</Label>
                  <Input
                    value={bookData?.phone}
                    onChange={(e) => onInputChange("phone", e.target.value)}
                  />
                </div>
              </div>
              <div className="flex space-x-5  ">
                <div className="flex-1 space-y-1">
                  <Label>City</Label>
                  <Input
                    value={bookData?.city}
                    onChange={(e) => onInputChange("city", e.target.value)}
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Country</Label>
                  <Select
                    value={bookData?.country}
                    onChange={(e) => onInputChange("country", e.target.value)}
                  >
                    {countries.map((c) => (
                      <option key={c.name} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              {!isBookDataFilled() && (
                <div className="pt-4">
                  <h6 className="text-red-400 text-lg font-semibold">
                    Please, fill all fields
                  </h6>
                </div>
              )}
              {isBookDataFilled() &&
                bookData.email !== undefined &&
                !isEmailValid(bookData.email) && (
                  <div className="pt-4">
                    <h6 className="text-red-400 text-lg font-semibold">
                      Please, set valid Email
                    </h6>
                  </div>
                )}
              <div className="flex space-x-5  ">
                <div className="flex-1 space-y-1">
                  <Label>Coupon code</Label>
                  <Input
                    value={bookData.couponCode}
                    onChange={(e) => checkCouponCodeHandler(e.target.value)}
                  />
                </div>
              </div>
              {couponChecking && (
                <div>
                  <div className="relative flex justify-center">
                    <span className="animate-spin ml-5">
                      <i className="las la-spinner text-2xl text-primary-500"></i>
                    </span>
                  </div>
                </div>
              )}

              {!coupon && !couponChecking && bookData.couponCode && (
                <div className="pt-4">
                  <h6 className="text-red-400 text-lg font-semibold">
                    Coupon code is not valid
                  </h6>
                </div>
              )}

              {coupon && !couponChecking && bookData.couponCode && (
                <div className="pt-4">
                  <h6 className="text-green-400 text-lg font-semibold">
                    {`You have ${coupon.discount} ${
                      coupon.calculatedBy === "Fixed" ? "euro" : "%"
                    } discount!`}
                  </h6>
                </div>
              )}

              <div className="flex space-x-5  ">
                <div className="flex-1 space-y-1">
                  <Label>You can leave a message</Label>
                  <Textarea
                    placeholder=""
                    rows={5}
                    value={bookData?.message}
                    onChange={(e) => onInputChange("message", e.target.value)}
                  />
                </div>
              </div>
              <div className="pt-4">
                <ButtonPrimary
                  disabled={
                    !isBookDataFilled() ||
                    !bookData.email ||
                    !isEmailValid(bookData.email)
                  }
                  onClick={confirmHandler}
                >
                  Confirm and book
                  {loading ? (
                    <span className="animate-spin ml-5">
                      <i className="las la-spinner text-lg"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default NoxioReservePage;
