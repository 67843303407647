import moment from "moment";

export default function isDayBlocked(
  day: moment.Moment,
  blockedDates?: string[],
  checkinInFuture?: number | null,
  dateBeforeCheckin?: number | null
): boolean {
  const cloneDay = day.clone();

  const isBlocked = blockedDates
    ? blockedDates.some((blockedDate) => moment(blockedDate).isSame(day, "day"))
    : false;

  const isTooFarInFuture = checkinInFuture
    ? day.isSameOrAfter(moment().add(checkinInFuture, "days"))
    : false;

  const isDateBeforeCheckin = dateBeforeCheckin
    ? cloneDay
        .startOf("day")
        .isBefore(moment().startOf("day").add(dateBeforeCheckin, "days"))
    : false;

  return isBlocked || isTooFarInFuture || isDateBeforeCheckin;
}
