import { AppContext, AppContextInterface } from "context/appContext";
import React from "react";
import mediaPath from "utils/mediaPath";

import NoxioLogo from "../../images/logos/noxio-logo.png";

const LogoSvg = () => {
  const { bookingEngineInfo } = React.useContext(AppContext) as AppContextInterface;

  if (!bookingEngineInfo) return null;

  const isDefault = !bookingEngineInfo.customization.logo;

  return (
    <img
      className={`w-32 h-12 ${
        isDefault ? "object-contain" : "object-cover"
      } block dark:hidden`}
      alt="logo"
      src={
        isDefault ? NoxioLogo : `${mediaPath()}/${bookingEngineInfo.customization.logo}`
      }
    />
  );
};

export default LogoSvg;
