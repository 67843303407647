import { NoxioPropertyDataType } from "data/types";

export default function maximalPrice(property: NoxioPropertyDataType | null) {
  if (!property) {
    return 0;
  } else {
    if (!property.ratePlan) return property.standardPrice;
    const { datePrices, weeklyBasePrices, monthlyBasePrices } = property.ratePlan;
    const prices = [
      ...(datePrices ? Object.values(datePrices) : []),
      ...(weeklyBasePrices ? Object.values(weeklyBasePrices) : []),
      ...(monthlyBasePrices ? Object.values(monthlyBasePrices) : []),
      property.standardPrice,
    ];
    return Math.max.apply(null, prices);
  }
}
