import { NoxioPropertyDataType } from "data/types";
import moment from "moment";

export default function propertyPrice(
  startDate: moment.Moment,
  endDate: moment.Moment,
  property: NoxioPropertyDataType
) {
  const paidDays = [];

  for (var dt = startDate.clone(); dt < endDate; dt = dt.add(1, "days")) {
    let newDate = dt.clone().startOf("day");
    paidDays.push(newDate);
  }

  let resultPrice: number = 0;

  if (!property.ratePlan) {
    return property.standardPrice * paidDays.length;
  }

  for (let index = 0; index < paidDays.length; index++) {
    const day = paidDays[index];
    resultPrice += getDayPrice(property.ratePlan, property.standardPrice, day);
  }

  return resultPrice;
}

const getDayPrice = (
  ratePlanData: any,
  standardPrice: number,
  currentDay: moment.Moment
) => {
  if (!ratePlanData) return standardPrice;

  const dayKey = currentDay.toISOString();

  const { datePrices, weeklyBasePrices, monthlyBasePrices } = ratePlanData;

  if (datePrices && datePrices[dayKey]) return datePrices[dayKey];

  const dayOfWeek = currentDay.format("dddd").toLowerCase();
  if (weeklyBasePrices && weeklyBasePrices[dayOfWeek]) return weeklyBasePrices[dayOfWeek];

  const month = currentDay.format("MMMM").toLowerCase();
  if (monthlyBasePrices && monthlyBasePrices[month]) return monthlyBasePrices[month];

  return standardPrice;
};
