import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { NoxioPropertyDataType } from "data/types";
import NoxioGallerySlider from "components/GallerySlider/NoxioGallerySlider";
import { RouteParams } from "routers/types";
import minimalPrice from "utils/minimalPrice";
import propertyPrice from "utils/propertyPrice";
import moment from "moment";
import Badge from "shared/Badge/Badge";

export interface NoxioPropertyCardHProps {
  className?: string;
  data: NoxioPropertyDataType;
  isSelectedDates?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  guestAdults?: string;
  guestChildren?: string;
  guestInfants?: string;
  onShowOnMap?: any;
}

const NoxioPropertyCardH: FC<NoxioPropertyCardHProps> = ({
  className = "",
  data,
  isSelectedDates = false,
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  onShowOnMap,
}) => {
  const {
    _id,
    publicName,
    urlName,
    beds,
    bathrooms,
    unitSize,
    media,
    summaryDescription,
    type,
  } = data;
  const params = useParams<RouteParams>();

  const showOnMapHandler = () => {
    onShowOnMap();
  };

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <NoxioGallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={media.slice(0, 4)}
          className="w-full h-full rounded-2xl overflow-hidden"
          uniqueID={`PropertyCardH-${Date.now()}-${_id}`}
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {beds} beds
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {bathrooms} baths
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {unitSize} Sq. Fit
          </span>
        </div>
      </div>
    );
  };

  const renderPrice = () => {
    const price =
      isSelectedDates &&
      startDate &&
      endDate &&
      startDate.isValid() &&
      endDate.isValid() ? (
        <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
          Total price is &euro;{`${propertyPrice(startDate, endDate, data)}`}
        </span>
      ) : (
        <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
          From &euro;{`${minimalPrice(data)}`}
        </span>
      );

    return (
      <div className="flex space-x-4 w-full justify-end items-end">
        {onShowOnMap && (
          <button
            onClick={(e) => {
              e.preventDefault();
              showOnMapHandler();
            }}
            className="hidden xl:flex items-center justify-center px-3 py-2 hover:bg-primary-500 border border-primary-500 rounded leading-none text-base font-medium text-primary-500 hover:text-white"
          >
            Show on map
          </button>
        )}
        {price}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="flex justify-between items-center">
            <Badge name={type[0].name} />
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{publicName}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div
            className="max-h-25 card-description"
            dangerouslySetInnerHTML={{
              __html:
                summaryDescription?.replace(
                  "font-family: Roboto, Helvetica, Arial, sans-serif;",
                  ""
                ) ?? "",
            }}
          ></div>
          {renderPrice()}
        </div>
      </div>
    );
  };

  let searchString =
    isSelectedDates && startDate && endDate && startDate.isValid() && endDate.isValid()
      ? `?startDate=${startDate?.format("YYYY-MM-DD")}&endDate=${endDate?.format(
          "YYYY-MM-DD"
        )}&`
      : "";
  searchString += `${
    isSelectedDates && startDate && endDate && startDate.isValid() && endDate.isValid()
      ? ""
      : "?"
  }guestAdults=${guestAdults ?? 2}&guestChildren=${guestChildren ?? 0}&guestInfants=${
    guestInfants ?? 0
  }`;

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <Link
        to={{
          pathname: `/${params.account}/stay-detail/${urlName}${searchString}`,
          state: {
            property: data,
          },
        }}
        target="_blank"
        className="h-full w-full flex flex-col sm:flex-row sm:items-center"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default NoxioPropertyCardH;
