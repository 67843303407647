import axios from "axios";

let instance = axios.create({
  baseURL:
    process.env.REACT_APP_STAGE === "local"
      ? "http://localhost:8080/api/bookingEngine"
      : process.env.REACT_APP_STAGE === "production"
      ? "https://my.noxio.app/api/bookingEngine"
      : "",
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = `/`;
    }
    return error;
  }
);

export default instance;
