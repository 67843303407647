import React, { FC } from "react";
import { NoxioPropertyDataType } from "data/types";
import { Link, useParams } from "react-router-dom";
import NoxioGallerySlider from "components/GallerySlider/NoxioGallerySlider";
import propertyPrice from "utils/propertyPrice";
import { RouteParams } from "routers/types";
import minimalPrice from "utils/minimalPrice";

export interface NoxioStayCardProps {
  className?: string;
  data: NoxioPropertyDataType;
  isSelectedDates?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  guestAdults?: string;
  guestChildren?: string;
  guestInfants?: string;
  size?: "default" | "small";
}
const NoxioStayCard: FC<NoxioStayCardProps> = ({
  size = "default",
  className = "",
  data,
  isSelectedDates = false,
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
}) => {
  const { _id, publicName, urlName, beds, address, media } = data;

  const params = useParams<RouteParams>();

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <NoxioGallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={media.slice(0, 4)}
          className="w-full h-full rounded-2xl overflow-hidden"
          uniqueID={`PropertyCard-${Date.now()}-${_id}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {beds} beds
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{publicName}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address.address1}</span>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {isSelectedDates && data ? "" : "From "}
            &euro;
            {`${
              startDate?.isValid() && endDate?.isValid() && data
                ? propertyPrice(startDate, endDate, data)
                : minimalPrice(data)
            }`}
          </span>
        </div>
      </div>
    );
  };

  const searchString = isSelectedDates
    ? `?startDate=${startDate?.format("YYYY-MM-DD")}&endDate=${endDate?.format(
        "YYYY-MM-DD"
      )}&guestAdults=${guestAdults}&guestChildren=${guestChildren}&guestInfants=${guestInfants}`
    : "";

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link
        to={{
          pathname: `/${params.account}/stay-detail/${urlName}${searchString}`,
          state: {
            property: data,
          },
        }}
        target="_blank"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default NoxioStayCard;
