import { Transition } from "@headlessui/react";
import NoxioStayCard from "components/StayCard/NoxioStayCard";
import { NoxioPropertyDataType } from "data/types";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import minimalPrice from "utils/minimalPrice";
import propertyPrice from "utils/propertyPrice";

export interface NoxioAnyReactComponentProps {
  className?: string;
  data?: NoxioPropertyDataType;
  isSelected?: boolean;
  lat: number;
  lng: number;
  isSelectedDates?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  guestAdults?: string;
  guestChildren?: string;
  guestInfants?: string;
  showCard?: boolean;
}

const NoxioAnyReactComponent: FC<NoxioAnyReactComponentProps> = ({
  className = "",
  data,
  isSelected,
  startDate,
  endDate,
  guestAdults,
  guestChildren,
  guestInfants,
  showCard = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`nc-AnyReactComponent relative  ${className} ${
        isSelected ? "z-50" : ""
      }`}
      data-nc-id="AnyReactComponent"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {setIsOpen(false)}}
    >
      <span
        className={`flex px-2 py-1 rounded-lg bg-white dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-neutral-900 hover:text-white dark:hover:bg-white dark:hover:text-neutral-900 transition-colors ${
          isSelected
            ? "bg-neutral-900 text-white dark:bg-white dark:text-neutral-900"
            : ""
        }`}
      >
        {startDate?.isValid() && endDate?.isValid() && data ? "" : "From "}
        &euro;
        {`${
          startDate?.isValid() && endDate?.isValid() && data
            ? propertyPrice(startDate, endDate, data)
            : minimalPrice(data ?? null)
        }`}
      </span>
      <Transition
        show={isOpen || showCard}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
          {data && (
            <NoxioStayCard
              data={data}
              className="shadow-2xl"
              isSelectedDates={startDate?.isValid() && endDate?.isValid()}
              startDate={startDate}
              endDate={endDate}
              guestAdults={guestAdults}
              guestChildren={guestChildren}
              guestInfants={guestInfants}
            />
          )}
        </div>
      </Transition>
    </div>
  );
};

export default NoxioAnyReactComponent;
