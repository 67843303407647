import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import { Range } from "rc-slider";
import { setUrlParameter, setUrlParameters } from "utils/setUrlParameter";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import __noxioTypesOfPropery from "../../data/jsons/__noxioTypesOfProperty.json";
import { NoxioPropertyDataType } from "data/types";
import NoxioService from "services/NoxioService";
import { useParams } from "react-router-dom";
import { RouteParams } from "routers/types";
export interface NoxioTabFiltersProps {
  filters: any;
}

const NoxioTabFilters: FC<NoxioTabFiltersProps> = ({ filters }) => {
  const {
    rangePrices,
    setRangePrices,
    beds,
    setBeds,
    bedrooms,
    setBedrooms,
    bathrooms,
    setBathrooms,
    propertyTypes,
    setPropertyTypes,
  } = filters;

  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentRangePrices, setCurrentRangePrices] = useState(rangePrices);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const [noxioTypesOfPropery, setNoxioTypesOfPropery] = useState<string[]>([]);
  const [maxBeds, setMaxBeds] = useState<number>(0);
  const [maxBedrooms, setMaxBedrooms] = useState<number>(0);
  const [maxBathrooms, setMaxBathrooms] = useState<number>(0);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);
  //
  const params = useParams<RouteParams>();

  useEffect(() => {
    const getProperties = async () => {
      try {
        const newProperties = await new NoxioService().getAllProperty(params.account);
        const newNoxioTypesOfPropery = Array.from(
          new Set(newProperties.map((x) => x.type[0].name))
        );
        setNoxioTypesOfPropery(newNoxioTypesOfPropery);

        setMaxBeds(
          Math.max.apply(
            Math,
            newProperties.map((p) => p.beds)
          )
        );
        setMaxBedrooms(
          Math.max.apply(
            Math,
            newProperties.map((p) => p.bedrooms)
          )
        );
        setMaxBathrooms(
          Math.max.apply(
            Math,
            newProperties.map((p) => p.bathrooms)
          )
        );
      } catch (error) {
        window.location.href = `/`;
      }
    };

    getProperties();
  }, []);
  //
  const changeRangePricesHandler = (rangePrices: number[]) => {
    clearTimeout(timeout.current as NodeJS.Timeout);

    setCurrentRangePrices(rangePrices);

    timeout.current = setTimeout(() => {
      setUrlParameter("minPrice", rangePrices[0].toString());
      setUrlParameter("maxPrice", rangePrices[1].toString());
      setRangePrices(rangePrices);
    }, 500);
  };

  const clearPropertyTypesHandler = () => {
    setUrlParameters("placeType", []);
    setPropertyTypes([]);
  };

  const clearRoomsAndBedsHandler = () => {
    setUrlParameter("bedrooms", "0");
    setUrlParameter("bathrooms", "0");
    setUrlParameter("beds", "0");
    setBeds(0);
    setBedrooms(0);
    setBathrooms(0);
  };

  const clearModalHandler = () => {
    setUrlParameter("minPrice", "0");
    setUrlParameter("maxPrice", "10000");
    setCurrentRangePrices([0, 10000]);
    setRangePrices([0, 10000]);
    clearRoomsAndBedsHandler();
    closeModalMoreFilterMobile();
    clearPropertyTypesHandler();
  };

  const propertyTypeChangeHandler = (isAdd: boolean, name: string) => {
    let newTypes: string[] = [];

    if (isAdd) {
      newTypes = [...propertyTypes, name];
    } else {
      newTypes = propertyTypes.filter((x: string) => x !== name);
    }

    setUrlParameters("placeType", newTypes);
    setPropertyTypes(newTypes);
  };

  const renderTabsTypeOfPlace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type of place</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {noxioTypesOfPropery.map((item) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          onChange={(value) => {
                            propertyTypeChangeHandler(value, item);
                          }}
                          defaultChecked={propertyTypes.some((x: string) => x === item)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        clearPropertyTypesHandler();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderXClear = () => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          clearModalHandler();
        }}
        className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderPriceXClear = () => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setUrlParameter("minPrice", "0");
          setUrlParameter("maxPrice", "10000");
          setCurrentRangePrices([0, 10000]);
          setRangePrices([0, 10000]);
        }}
        className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsRoomAndBeds = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Rooms and Beds</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <NcInputNumber
                      label="Beds"
                      max={maxBeds}
                      defaultValue={beds}
                      onChange={(value) => {
                        setUrlParameter("beds", value.toString());
                        setBeds(value);
                      }}
                    />
                    <NcInputNumber
                      label="Bedrooms"
                      max={maxBedrooms}
                      defaultValue={bedrooms}
                      onChange={(value) => {
                        setUrlParameter("bedrooms", value.toString());
                        setBedrooms(value);
                      }}
                    />
                    <NcInputNumber
                      label="Bathrooms"
                      max={maxBathrooms}
                      defaultValue={bathrooms}
                      onChange={(value) => {
                        setUrlParameter("bathrooms", value.toString());
                        setBathrooms(value);
                      }}
                    />
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        clearRoomsAndBedsHandler();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none `}
            >
              <span>
                &euro;{currentRangePrices[0]} - &euro;{currentRangePrices[1]}
              </span>
              {renderPriceXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Total Price</span>
                      <Range
                        className="text-red-400"
                        min={0}
                        max={10000}
                        defaultValue={[currentRangePrices[0], currentRangePrices[1]]}
                        value={[currentRangePrices[0], currentRangePrices[1]]}
                        allowCross={false}
                        onChange={changeRangePricesHandler}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">&euro;</span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={currentRangePrices[0]}
                            onChange={(e) => {
                              changeRangePricesHandler([
                                e.target.value,
                                currentRangePrices[1],
                              ]);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">&euro;</span>
                          </div>
                          <input
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={currentRangePrices[1]}
                            onChange={(e) => {
                              changeRangePricesHandler([
                                currentRangePrices[0],
                                e.target.value,
                              ]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setUrlParameter("minPrice", "0");
                        setUrlParameter("maxPrice", "10000");
                        setCurrentRangePrices([0, 10000]);
                        setRangePrices([0, 10000]);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span>Filters</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Type of place</h3>
                        <div className="mt-6 grid grid-cols-2 gap-8 ">
                          {__noxioTypesOfPropery.map((item) => (
                            <div key={item.name} className="">
                              <Checkbox
                                name={item.name}
                                label={item.name}
                                onChange={(value) => {
                                  propertyTypeChangeHandler(value, item.name);
                                }}
                                defaultChecked={propertyTypes.some(
                                  (x: string) => x === item.name
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Range
                                className="text-red-400"
                                min={0}
                                max={10000}
                                defaultValue={[
                                  currentRangePrices[0],
                                  currentRangePrices[1],
                                ]}
                                value={[currentRangePrices[0], currentRangePrices[1]]}
                                allowCross={false}
                                onChange={changeRangePricesHandler}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={currentRangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={currentRangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Rooms and beds</h3>
                        <div className="mt-6 relative flex flex-col space-y-5">
                          <NcInputNumber
                            label="Beds"
                            max={10}
                            defaultValue={beds}
                            onChange={(value) => {
                              setUrlParameter("beds", value.toString());
                              setBeds(value);
                            }}
                          />
                          <NcInputNumber
                            label="Bedrooms"
                            max={10}
                            defaultValue={bedrooms}
                            onChange={(value) => {
                              setUrlParameter("bedrooms", value.toString());
                              setBedrooms(value);
                            }}
                          />
                          <NcInputNumber
                            label="Bathrooms"
                            max={10}
                            defaultValue={bathrooms}
                            onChange={(value) => {
                              setUrlParameter("bathrooms", value.toString());
                              setBathrooms(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={clearModalHandler}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfPlace()}
        {renderTabsPriceRage()}
        {renderTabsRoomAndBeds()}
        {/* {renderTabMoreFilter()} */}
      </div>
      {renderTabMoreFilterMobile()}
    </div>
  );
};

export default NoxioTabFilters;
